.trending {
  padding: 60px 0;
  width: 100%;
}
.trending-heading {
  text-align: center;
  font-size: 56px;
  margin-bottom: 50px;
}

.category-item {
  overflow: hidden;
  position: relative;
  height: 100% !important;
}

.category-item:first-child {
  grid-column: span 1;
  grid-row: span 2;
}

.category-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.7s;
}

.category-item:hover img {
  transform: scale(1.08);
}

.category-title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 24px;
  text-align: center;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.7s;
}

.category-item:hover .category-title {
  opacity: 1;
  transform: translateY(0);
  color: #fff;
}
