/* .banner {
  height: 100vh;
  position: relative;
}
.banner-next,
.banner-prev {
  position: absolute;
  top: 50%;
  font-size: 50px;
  z-index: 10;
  cursor: pointer;
  padding: 12px 18px;
}
.banner-next {
  right: 14px;
}
.banner-prev {
  left: 14px;
}
.banner-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.banner-main {
  display: flex;
  position: relative;
  transition: transform 0.25s linear;
  height: 100%;
}
.banner-item {
  height: 100%;
  width: 100%;
  flex: 1 0 100%;
  user-select: none;
}
.banner-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner i {
  color: #000;
  background: #fff;
  border-radius: 50%;
  padding: 8px;
  font-size: 16px;
  transition: all 0.25s linear;
}
.banner i:hover {
  background: tomato;
  color: #fff;
}
body {
  font-family: "Courier New", Courier, monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
}
header .logo img {
  height: 40px;
}
header {
  width: 100%;
  max-width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 50px;
}
header nav ul {
  display: flex;
  justify-content: end;
  align-items: center;
}
header nav ul li {
  list-style: none;
  margin-left: 60px;
  color: #fff;
}
.banner {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -50px;
}
.banner .content {
  text-align: center;
  color: red;
  text-shadow: 0 10px 50px #63y0707;
}
.banner .title {
  font-size: 6em;
  animation: animationBlur 5s linear infinite;
}
@keyframes animationBlur {
  0%,
  80% {
    filter: blur(0);
  }
  90% {
    filter: blur(5px);
  }
  100% {
    filter: blur(0);
  }
}

.banner .bgvideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  filter: brightness(0.4);
}

.banner .content .desc {
  text-align: left;
  rotate: -5deg;
} */

.banner {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0 5%;
}
.banner-content {
  text-align: center;
  display: block;
  margin: 80px auto;
}
.banner-desc {
  line-height: 26px;
  margin: 0 auto;
  max-width: 800px;
  color: #ffffff;
  margin-bottom: 70px;
  font-size: 17px;
}
.banner-button {
  color: #ffffff;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  transition: all 0.2s linear;
}
.banner-heading {
  font-size: 70px;
  color: #ffffff;
  margin-bottom: 28px;
}
.banner-button:hover {
  background-color: #ffffff;
  color: #000000;
}
.bgvideo {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
  .bgvideo {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  .bgvideo {
    width: auto;
    height: 100%;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(80px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner-main {
  animation: fadeInUp 2.6s ease-in-out;
}
@media screen and (max-width: 767px) {
  .banner-heading {
    font-size: 40px;
  }
  .banner-desc {
    font-size: 14px;
  }
  .banner-button {
    padding: 10px 20px;
  }
}
