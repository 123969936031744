.header.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.header-contact {
  padding: 10px 0;
  background-color: #002c3f;
}
.header-phone {
  color: white;
  font-size: 18px;
  text-align: center;
}
.container-fixed {
  max-width: 1440px;
  margin: 0 auto;
}
