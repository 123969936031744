@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.slider-container {
  padding: 0 60px; /* Tạo khoảng cách giữa slider và lề trang web */
  margin-bottom: 70px;
}

.slick-slide-item {
  padding: 0 12px; /* Tạo khoảng cách giữa các hình ảnh */
}

.slick-slide-item .img-container {
  height: 400px; /* Đặt chiều cao cố định cho container của hình ảnh */
  overflow: hidden; /* Ẩn phần của hình ảnh nằm ngoài container */
  position: relative; /* Đặt vị trí tương đối cho container để hình ảnh có thể phóng to trong nó */
}

.slick-slide-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Đảm bảo rằng tất cả các hình ảnh có cùng kích thước */
  transition: transform 1s ease-in-out; /* Thêm hiệu ứng chuyển đổi khi phóng to hình ảnh */
}

.slick-slide-item:hover img {
  transform: scale(1.15); /* Phóng to hình ảnh khi di chuột qua */
}

/* ...rest of your CSS... */

.slick-next:before,
.slick-prev:before {
  color: #605f5f !important; /* Thay đổi màu của các nút */
  font-size: 32px !important; /* Tăng kích thước của các nút */
}

.slick-next,
.slick-prev {
  z-index: 1; /* Đảm bảo rằng các nút luôn hiển thị trên hình ảnh */
  margin: -18px; /* Tạo khoảng cách giữa nút và hình ảnh */
}
.ri-instagram-fill {
  color: #0d0802;
  font-size: 32px;
}
/* ...rest of your CSS... */

.modal {
  display: flex; /* Add this line */
  align-items: center; /* Add this line */
  justify-content: center; /* Add this line */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  max-height: 86vh;
  object-fit: contain;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
