.category {
  padding-bottom: 50px;
}
.category-heading {
  text-align: center;
  font-size: 42px;
  margin: 30px 0;
}
.category-list {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.category-item {
  box-sizing: border-box;
  text-align: center;
}

.category-img {
  overflow: hidden;
  margin-bottom: 10px;
}

.category-img img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Hiệu ứng chuyển đổi */
}

.category-item:hover img {
  transform: scale(1.05); /* Phóng to ảnh khi hover */
  opacity: 0.85;
}

.category-title {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
@media screen and (max-width: 1023px) {
  .category-list {
    grid-template-columns: repeat(2, 2fr);
  }
}
@media screen and (max-width: 767px) {
  .category-list {
    grid-template-columns: repeat(1, 2fr);
  }
}
