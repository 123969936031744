.testimonial {
  padding-top: 40px;
}
.testimonial .row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap; /* Đảm bảo rằng các mục vẫn trên cùng một hàng */
}
.testimonial .col-3 {
  flex: 1; /* Chia đều các mục trên hàng */
  box-sizing: border-box; /* Giữ kích thước của mục trong phạm vi kích thước hàng */
  margin: 0 20px; /* Tạo khoảng cách giữa các item */
}
.testimonial .col-3 {
  text-align: center;
  padding: 40px 20px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testimonial .col-3 img {
  width: 60px;
  margin-top: 20px;
  border-radius: 50%;
}
.testimonial .col-3:hover {
  transform: translateY(-10px);
}
.ri-chat-quote-fill {
  font-size: 34px;
  color: #ff523b;
}

.brands {
  margin: 100px auto;
}
.col-5 {
  width: 160px;
  overflow: hidden;
}
.col-5 img {
  width: 100%;
  cursor: pointer;
  opacity: 1;
  transition: 0.5s;
}
.col-5 img:hover {
  opacity: 0.6;
}
@media screen and (max-width: 1279px) {
  .testimonial .row {
    flex-direction: column;
  }
}
