.quest-heading {
    font-size: 42px;
    text-align: left;
    padding: 40px 0;
  }
  .quest-list {
    display: flex;
    gap: 100px;
  }
  .quest-item {
    width: calc(100% - 50px);
  }
  .quest-title {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .quest-title-24 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .quest-desc {
    margin-bottom: 20px;
  }
  .quest-desc-min {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .quest-font-18 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .quest-color-black{
    color: black;
  }
  .quest-content {
    margin-bottom: 10px;
  }
  