.detail-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  padding: 50px 0;
}
.detail-heading {
  margin-bottom: 30px;
}
.detael-image {
  width: calc(60% - 25px);
  display: flex;
  gap: 50px;
  justify-content: center;
}
.detael-image img {
  max-width: 360px;
  height: 460px;
  object-fit: cover;
}
.detail-image-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.detail-image-list .detail-image-item {
  max-width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}
.detail-image-list .detail-image-item.active {
  border: 3px solid #000000;
}
.detail-info {
  width: calc(40% - 25px);
}
.detail-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.detail-price-item span {
  margin-right: 20px;
}
.detail-color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.detail-color-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.detail-color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.detail-color-item.active {
  padding: 15px;
  border: 5px solid #000000;
}
.detail-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.detail-quantity-prev,
.detail-quantity-next {
  display: inline-block;
  padding: 8px 10px;
  background-color: #4a1f18;
  border: 1px solid #7b88a8;
  transition: 0.3s linear;
}

.detail-quantity-prev i,
.detail-quantity-next i {
  color: #fff;
  font-size: 16px;
  pointer-events: none;
}

.detail-quantity-prev:hover,
.detail-quantity-next:hover {
  background-color: #7b88a8;
}

.detail-quantity-prev {
  border-radius: 5px 0 0 5px;
}

.detail-quantity-next {
  border-radius: 0 5px 5px 0;
}
.detail-quantity-number {
  width: 50px;
  padding: 8px 10px;
}
.detail-quantity-number::-webkit-outer-spin-button,
.detail-quantity-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.detail-add {
  margin-bottom: 30px;
  padding: 8px 14px;
  width: 100%;
  border-radius: 6px;
  background-color: #4a1f18;
  color: #fff;
  transition: 0.3s all linear;
}
.detail-add:hover {
  background-color: #ffffff;
  color: #000000;
}
@media screen and (max-width: 1023px) {
  .detail-content {
    flex-direction: column;
    gap: 30px;
  }
  .detael-image {
    width: 100%;
  }
  .detail-info {
    width: 100%;
  }
  .detail-price,
  .detail-color,
  .detail-quantity {
    /* flex-direction: column; */
    gap: 20px;
  }
  .detail-quantity {
    flex-direction: row;
    align-items: flex-start;
  }
  .detail-quantity-prev,
  .detail-quantity-next {
    width: 100%;
    border-radius: 6px;
  }
  .detail-quantity-number {
    width: 100%;
  }
  .detail-heading {
    text-align: center;
  }
  .detail-quantity-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .detail-image-list {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    flex-direction: row;
  }
  .detael-image img {
    width: 100%;
  }
  .detail-image-list .detail-image-item {
    width: 80px;
    height: 80px;
  }
  .detail-image-list .detail-image-item img {
    width: 100%;
    height: 100%;
  }
  .detail-price,
  .detail-color,
  .detail-quantity {
    flex-direction: column;
    gap: 20px;
  }
  .detail-quantity {
    flex-direction: column;
    align-items: center;
  }
  .detail-quantity-prev,
  .detail-quantity-next {
    width: 100%;
    border-radius: 6px;
  }
  .detail-quantity-number {
    width: 100%;
  }
  .detail-quantity-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .detael-image {
    flex-direction: column;
  }
}
