.checkout {
  padding: 0 0 30px 0;
}
.checkout-headng {
  font-size: 36px;
  font-weight: 500;
  padding: 50px 0 20px;
}
.checkout-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.checkout-form-discout {
  width: calc(30% - 10px);
  right: 0px;
  top: 105%;
  padding: 20px;
  border: 1px solid #000000;
}
.checkout-form-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.checkout-left {
  width: calc(70% - 10px);
}
.checkout-address-info {
  padding: 25px 20px;
  border: 1px solid #000000;
}
.checkout-address-heding {
  margin-bottom: 20px;
}
.checkout-address-form {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;
}
.checkout-address-form-item input {
  padding: 8px 20px 8px 10px;
  width: 100%;
}
.checkout-address-form-item.textarea {
  grid-column: 1 / 3;
}
.checkout-right {
  width: calc(30% - 10px);
  padding: 25px 20px;
  border: 1px solid #000000;
}
.checkout-pay {
  display: flex;
  align-items: center;
  gap: 50px;
}
.checkout-pay-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.checkout-pay-note {
  width: 510px;
  height: 180px;
  padding: 8px 0 0 10px;
}
.checkout-pay-item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.checkout-pay-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.form-discout-text {
  margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
  .checkout-wrapper {
    flex-direction: column;
  }
  .checkout-form-discout {
    width: 100%;
  }
  .checkout-left {
    width: 100%;
  }
  .checkout-right {
    width: 100%;
  }
  .checkout-pay-note {
    width: 61vw;
  }
}
@media screen and (max-width: 1023px) {
  .checkout-pay-note {
    width: 58vw;
  }
}
@media screen and (max-width: 767px) {
  .checkout-address-form {
    display: block;
  }
  .checkout-pay-note {
    width: 100%;
  }
}
