.offer {
  background-color: #f3e5d7;
  padding: 100px 0;
}
.offer-content {
  display: flex;
}
.offer-item {
  width: 50%;
  margin-right: 20px;
}
.offer-item img {
  width: 100%;
}
.offer-info {
  width: 50%;
  padding: 50px;
  margin-left: 20px;
}
.offer-heading {
  font-size: 64px;
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 50px;
}
.offer-desc {
  font-size: 18px;
  line-height: 1.5;
  padding-right: 50px;
  margin-bottom: 50px;
}
.offer-button {
  font-size: 20px;
  color: #ffffff;
  padding: 14px 32px;
  background-color: #000000;
  transition: 0.3s all linear;
}
.offer-button:hover {
  color: #000000;
  background-color: #ffffff;
}
@media screen and (max-width: 1279px) {
  .offer-content {
    flex-direction: column;
  }
  .offer-item {
    width: 100%;
    margin-right: 0;
  }
  .offer-info {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }
  .offer-heading {
    font-size: 48px;
  }
  .offer-desc {
    font-size: 16px;
  }
  .offer-button {
    font-size: 18px;
  }
}
