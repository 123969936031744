.about-heading {
  font-size: 42px;
  text-align: center;
  padding: 40px 0;
}
.about-list {
  display: flex;
  gap: 100px;
}
.about-item {
  width: calc(50% - 50px);
}
.about-image {
  margin: 0 auto;
}
.about-image-item {
  height: 400px;
  top: 100px;
}
.about-image img {
  height: 400px;
}
.about-title {
  font-size: 32px;
  margin-bottom: 20px;
}
.about-desc {
  margin-bottom: 20px;
  text-align: justify;
}
.about-content {
  margin-bottom: 50px;
}
@media screen and (max-width: 1023px) {
  .about-list {
    flex-direction: column;
    gap: 50px;
  }
  .about-item {
    width: 100%;
  }
  .about-image-item {
    height: 300px;
    margin-bottom: 50px;
  }
  .about-image img {
    height: 300px;
  }
}
