.content main {
  width: 100%;
  padding: 36px 24px;
  font-family: var(--poppins);
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.content main .head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.content main .head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--dark);
}

.content main .box-info {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-gap: 24px;
  margin-top: 36px;
}
.content main .box-info .box-info-item {
  padding: 24px;
  background: var(--light);
  border-radius: 20px;
  display: flex;
  align-items: center;
  grid-gap: 24px;
}
.content main .box-info .box-info-item i {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content main .box-info .box-info-item:nth-child(1) .bx {
  background: var(--light-blue);
  color: var(--blue);
}
.content main .box-info .box-info-item:nth-child(2) .bx {
  background: var(--light-yellow);
  color: var(--yellow);
}
.content main .box-info .box-info-item:nth-child(3) .bx {
  background: var(--light-orange);
  color: var(--orange);
}
.content main .box-info .box-info-item .text h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--dark);
}
.content main .box-info .box-info-item .text p {
  color: var(--dark);
}
.dropdown {
  width: 100%;
  max-width: 400px;
  position: relative;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #fff;
}
.dropdown__select {
  border-radius: inherit;
}
.dropdown__select * {
  pointer-events: none;
}
.dropdown__list {
  margin-top: 25px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
  z-index: 10;
}
.dropdown__list.show {
  display: block;
}
.dropdown__list:before {
  content: '';
  height: 25px;
  position: absolute;
  left: 0;
  right: 0;
  background-color: transparent;
  transform: translateY(-100%);
}
.dropdown__select,
.dropdown__item {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #7b88a8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: var(--dark);
  font-weight: 400;
  font-size: 14px;
}
.dropdown__item {
  transition: background-color 0.2s linear;
  background-color: #fff;
}
.dropdown__item * {
  pointer-events: none;
}
.dropdown__item:first-child {
  border-radius: 4px 4px 0 0;
  position: relative;
}
.dropdown__item:first-child:before {
  content: '';
  position: absolute;
  top: 0;
  left: 30px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #7b88a8;
  transform: translateY(-100%);
  transition: border-color 0.2s linear;
}
.dropdown__item:first-child:hover:before {
  border-bottom-color: var(--blue);
}
.dropdown__item:last-child {
  border-radius: 0 0 4px 4px;
}
.dropdown__item:hover {
  background-color: var(--blue);
}
.dropdown__text {
  text-align: center;
}

.content main .table-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}
.content main .table-data > div {
  border-radius: 20px;
  background: var(--light);
  padding: 24px;
  overflow-x: auto;
}
.content main .table-data .head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}
.content main .table-data .head h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}
.content main .table-data .head .bx {
  cursor: pointer;
}

.content main .table-data .order {
  flex-grow: 1;
  flex-basis: 350px;
  height: 600px;
  overflow-y: auto;
}
.content main .table-data .order.order-product {
  flex-grow: 1;
  flex-basis: 350px;
  height: 280px;
  overflow-y: auto;
}
.content main .table-data .order table {
  width: 100%;
  border-collapse: collapse;
}
.content main .table-data .order table th {
  padding-bottom: 12px;
  font-size: 13px;
  border-bottom: 1px solid var(--grey);
  text-align: center;
  vertical-align: middle;
}
.content main .table-data .order table td {
  padding: 16px 0;
  text-align: center;
  vertical-align: middle;
}

.content main .table-data .order table td a {
  color: var(--blue);
  font-size: 20px;
  transition: 0.3s linear;
  cursor: pointer;
}
.content main .table-data .order table td a i {
  pointer-events: none;
}
.content main .table-data .order table td a:hover {
  color: var(--dark);
}
.content main .table-data .order table td .delete-cate {
  color: var(--blue);
  font-size: 20px;
  transition: 0.3s linear;
  cursor: pointer;
}
.content main .table-data .order table td .delete-cate:hover {
  color: var(--dark);
}

.content main .table-data .order table td img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}
.content main .table-data .order table td p {
  max-width: 350px;
  margin: 0 auto;
}
.content main .table-data .order table tbody tr:hover {
  background: var(--grey);
}

.order::-webkit-scrollbar {
  width: 6px;
  margin-left: 20px;
}
.order::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 20px;
}
.order::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 20px;
}

.content main .table-data .order table tr td .status {
  font-size: 10px;
  padding: 6px 16px;
  color: var(--light);
  border-radius: 20px;
  font-weight: 700;
}
.content main .table-data .order table tr td .status.waiting {
  background: var(--dark-grey);
}
.content main .table-data .order table tr td .status.success {
  background: var(--blue);
}
.content main .table-data .order table tr td .status.preparing {
  background: var(--orange);
}
.content main .table-data .order table tr td .status.delivering {
  background: green;
}
.content main .table-data .order table tr td .status.cancelled {
  background: var(--red);
}

.box1 {
  margin-bottom: 50px;
}
