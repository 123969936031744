@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}
html {
  overflow-x: hidden;
}
:root {
  --poppins: 'Poppins', sans-serif;
  --lato: 'Lato', sans-serif;

  --light: #f9f9f9;
  --blue: #3c91e6;
  --light-blue: #cfe8ff;
  --grey: #eee;
  --dark-grey: #aaaaaa;
  --dark: #342e37;
  --red: #db504a;
  --yellow: #ffce26;
  --light-yellow: #fff2c6;
  --orange: #fd7238;
  --light-orange: #ffe0d3;
}

body.dark {
  --light: #0c0c1e;
  --grey: #060714;
  --dark: #fbfbfb;
}

body {
  background: var(--grey);
  overflow-x: hidden;
}
