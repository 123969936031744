/* .content main {
  width: 100%;
  padding: 36px 24px;
  font-family: var(--poppins);
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.content main .head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}
.content main .head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--dark);
}
.content main .table-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}
.content main .table-data > div {
  border-radius: 20px;
  background: var(--light);
  padding: 24px;
  overflow-x: auto;
}
.content main .table-data .head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}
.content main .table-data .head h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}
.content main .table-data .head .bx {
  cursor: pointer;
}

.content main .table-data .order {
  flex-grow: 1;
  flex-basis: 500px;
}
.content main .table-data .order table {
  width: 100%;
  border-collapse: collapse;
}
.content main .table-data .order table th {
  padding-bottom: 12px;
  font-size: 13px;
  border-bottom: 1px solid var(--grey);
  text-align: center;
}
.content main .table-data .order table .name {
  width: 200px;
}
.content main .table-data .order table th p {
  max-width: 180px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.content main .table-data .order table td {
  padding: 16px 0;
  text-align: center;
}
.content main .table-data .order table td p {
  max-width: 180px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.content main .table-data .order table td span {
  color: var(--blue);
  font-size: 20px;
  transition: 0.3s linear;
  cursor: pointer;
}
.content main .table-data .order table td span i {
  pointer-events: none;
}
.content main .table-data .order table td span:hover {
  color: var(--dark);
}
.content main .table-data .order table td img {
  width: 100px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}
.content main .table-data .order table tbody tr:hover {
  background: var(--grey);
}*/
.thanhphantrang1 {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.thanhphantrang1 li {
  list-style: none;
}

.thanhphantrang1 a {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
}

.thanhphantrang1 a:hover,
.thanhphantrang1 .active a {
  background-color: var(--blue);
  color: #fff;
}
