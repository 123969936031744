@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://cdn.jsdelivr.net/npm/remixicon@3.4.0/fonts/remixicon.css');
* {
  margin: 0;
  padding: 0;

  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
input,
textarea,
select,
button {
  outline: none;
}
input {
  line-height: normal;
}
label,
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
img {
  display: block;
  max-width: 100%;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  font-size: 16px;
  line-height: 1;
}
li {
  list-style-type: none;
}
.container {
  max-width: 1440px;
  margin: 0 auto;
}
@media screen and (max-width: 1279px) {
  .container {
    padding: 0 20px;
  }
}
