.infoUer-heading {
  font-size: 40px;
  line-height: 1.4;
  text-align: center;
  margin: 50px 0;
}
.infoUser-wrapper {
  display: flex;
  gap: 60px;
  margin-bottom: 100px;
}
.infoUser-image {
  width: calc(30% - 20px);
}
.infoUser-content {
  width: calc(50% - 20px);
  border: 1px solid #000000;
  border-radius: 20px;
}
.infoUser-packlink {
  width: calc(20% - 20px);
}
.infoUser-link {
  display: block;
  font-size: 17px;
  line-height: 1.4;
  margin: 20px 0;
  color: #000000;
}
.infoUser-link:first-child {
  margin: 0 0 20px;
}
.infoUser-link:hover {
  color: #4a1f18;
}
.infoUser-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid #000000;
  margin: 0 auto;
}
.infoUser-name {
  font-size: 23px;
  line-height: 1.3;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.infoUser-info {
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
  margin-top: 10px;
}
.infoUser-title {
  font-size: 23px;
  line-height: 1.4;
  margin: 20px 0 20px 20px;
}
.infoUser-text {
  display: block;
  font-size: 17px;
  line-height: 1.4;
  margin-bottom: 8px;
}
.infoUser-list {
  display: grid;
  grid-template-columns: repeat(1, 5fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 0 20px;
}
.infoUser-item input {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #000000;
}
.infoUser-button {
  display: block;
  padding: 12px 24px;
  border-radius: 10px;
  border: 1px solid #000000;
  background-color: #4a1f18;
  color: #ffffff;
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
  margin: 20px 0 20px 20px;
}
@media screen and (max-width: 1023px) {
  .infoUser-wrapper {
    flex-direction: column;
    gap: 20px;
  }
  .infoUser-content {
    width: 100%;
    padding: 20px;
  }
  .infoUser-packlink {
    width: 100%;
  }
  .infoUser-title {
    margin: 20px 0;
  }
  .infoUser-list {
    margin: 0;
  }
  .infoUser-button {
    margin: 20px 0;
  }
  .infoUser-image {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .infoUser-heading {
    font-size: 30px;
  }
  .infoUser-image img {
    width: 150px;
    height: 150px;
  }
  .infoUser-name {
    font-size: 20px;
  }
  .infoUser-info {
    font-size: 15px;
  }
  .infoUser-title {
    font-size: 20px;
  }
  .infoUser-text {
    font-size: 15px;
  }
  .infoUser-item input {
    padding: 10px;
  }
  .infoUser-button {
    padding: 10px 20px;
    font-size: 15px;
  }
}
