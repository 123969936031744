.content main {
    width: 100%;
    padding: 36px 24px;
    font-family: var(--poppins);
    max-height: calc(100vh - 56px);
    overflow-y: auto;
}
.content main .head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    flex-wrap: wrap;
}
.content main .head-title .left h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--dark);
}
.tab {
    padding: 25px;
    border-radius: 10px;
    background-color: white;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 50px auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 500px;
}
.tab-list {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}
.tab-block {
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 10px;
    color: #818e9b;
    background-color: #f4f5f7;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    flex: 1;
}
.tab-block.active {
    color: white;
    background-color: #2979ff;
    pointer-events: none;
}
.tab-block:not(:first-child) {
    margin-left: 15px;
}
.tab-content {
    display: none;
}
.tab-content.active {
    display: table;
}
.tab-additional {
    display: none;
}
.tab-additional.active {
    display: block;
}
.import {
    display: flex;
    align-items: center;
    gap: 100px;
}
.import-image {
    max-width: 150px;
    padding-top: 20px;
}
.checkout-address {
    margin-top: 20px;
}
.checkout-address-title {
    font-weight: 500;
    font-size: 24px;
    position: relative;
}

.checkout-address-box {
    margin-top: 24px;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
}
.checkout-address-box form {
    margin: 20px;
}
.checkout-address-list {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 20px;
    gap: 100px;
}
.checkout-address-input input::-webkit-outer-spin-button,
.checkout-address-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.checkout-address-input {
    margin-bottom: 30px;
}
.checkout-address-item {
    margin-top: 50px;
}
.checkout-address-item :first-child {
    margin-right: 30px;
}
.checkout-address-input label {
    display: inline-block;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 18px;
}
.checkout-address-input input,
.checkout-address-input textarea,
.checkout-address-input select {
    width: 400px;
    padding: 14px 20px;
    font-weight: 300;
    font-size: 14px;
    border: none;
    background-color: #eff1f9;
    border-radius: 5px;
}
.checkout-address-input input::placeholder,
.checkout-address-input textarea::placeholder,
.checkout-address-input select::placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #aaabb8;
}
.checkout-address-input img {
    width: 200px;
    height: 200px;
    border: 1px solid calc(--dark-grey);
    border-radius: 10px;
    object-fit: cover;
}
.checkout-address-input .submit {
    width: 400px;
    padding: 14px 20px;
    font-weight: 300;
    font-size: 14px;
    border: none;
    background-color: #3c91e6;
    border-radius: 5px;
    color: #eff1f9;
    transition: 0.3s all ease;
}
.checkout-address-input .submit:hover {
    background-color: var(--dark);
}
.demo {
    height: 300px;
}
.err {
    color: red;
    font-style: 8px;
    margin-top: 5px;
}
.checkout-address-input.checkbox {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.checkout-address-input .switch-form {
    display: block;
    max-width: 50px;
    height: 25px;
    border-radius: 25px;
    background: var(--grey);
    cursor: pointer;
    position: relative;
}
.checkout-address-input .switch-form::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: calc(25px - 4px);
    background: var(--red);
    border-radius: 50%;
    transition: all 0.3s ease;
}
.checkout-address-input #switch-hot:checked + .switch-form::before {
    left: calc(100% - (25px - 4px) - 2px);
    background: var(--blue);
}
.checkout-address-input #switch-an:checked + .switch-form::before {
    left: calc(100% - (25px - 4px) - 2px);
    background: var(--blue);
}
.checkout-address-input #switch-loai:checked + .switch-form::before {
    left: calc(100% - (25px - 4px) - 2px);
    background: var(--blue);
}
