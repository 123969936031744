.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}
.menu-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.menu-link {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease;
  color: #ffffff;
}
.menu-logo img {
  width: 100px;
  height: 65px;
  object-fit: cover;
  filter: invert(100%);
}
.menu-link:hover,
.menu-link i:hover {
  color: #ff9f1c;
}
.menu-link i {
  font-size: 18px;
  transition: all 0.3s ease;
}
.menu-item {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content .menu-link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.menu-item:hover .dropdown-content {
  display: block;
}
.dropdown-content .menu-link:hover {
  color: #ff9f1c;
}
.menu-toggle {
  display: none;
}
@media screen and (max-width: 1279px) {
  .menu-toggle {
    display: block;
    font-size: 26px;
    cursor: pointer;
    color: #ffffff;
  }
  .menu-list.toggle {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100vh;
    z-index: 1000;
    background-color: #4a1f18;
    transform: translateX(100%);
    transition: all 0.3s linear;
  }
  .menu-list.toggle.is-active {
    transform: translateX(0);
  }
}
