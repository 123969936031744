.cart-heading {
  font-size: 32px;
  padding-top: 60px;
}
.cart-comback {
  display: inline-block;
  font-size: 18px;
  color: #7b88a8;
  margin-bottom: 20px;
  transition: all 0.2s linear;
}
.cart-comback {
  color: #002c3f;
}
.cart-table {
  width: 100%;
  border: 1px solid #7b88a8;
}

.cart-table thead {
  background-color: #4a1f18;
}

.cart-table tr {
  max-width: 200px;
  min-height: 100px;
}

.cart-table thead tr td {
  padding: 17px 30px;
  font-weight: 500;
  color: #eff1f9;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}

.cart-table thead tr td:first-child {
  width: 80px;
}

.cart-table thead tr td:nth-child(2) {
  width: 230px;
}

.cart-table thead tr td:nth-child(3) {
  width: 330px;
}

.cart-table tbody tr td {
  padding: 10px;
  max-height: 50px;
  vertical-align: middle;
  text-align: center;
}

.cart-table tbody tr td:nth-child(3) {
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}

.cart-table tbody tr td img {
  max-width: 80px;
  background-color: #000000;
  border-radius: 10px;
  margin: 0 auto;
}
.cart-table tbody tr td p {
  width: 100px;
}

.cart-table tbody tr td .icon {
  font-size: 26px;
  color: #d9d9d9;
  transition: 0.3s linear;
}

.cart-table tbody tr td .icon:hover {
  color: #4a1f18;
}
.cart-table tbody tr td:nth-child(4),
.cart-table tbody tr td:nth-child(6) {
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

.cart-table tbody tr td input {
  max-width: 50px;
  min-height: 30px;
  text-align: center;
}

.cart-table tbody tr:not(:first-child) {
  border-top: 1px solid #eff1f9;
}

.cart-info {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  padding: 20px 0 50px;
}
.cart-table {
  width: calc(70% - 15px);
}
.cart-pay {
  width: calc(30% - 15px);
}
.cart-pay-item {
  padding: 16px;
  border: 1px solid #7b88a8;
}
.cart-pay-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 14px;
  text-transform: uppercase;
}
.cart-pay-total,
.cart-pay-discout,
.cart-pay-ship,
.cart-pay-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.cart-pay-price {
  font-weight: 500;
}
.cart-discout {
  margin: 40px 0 10px;
}
.pay-code {
  padding: 8px 16px 8px 4px;
  width: 70%;
  border-radius: 4px;
  border: 1px solid #7b88a8;
  margin-right: 10px;
}
.pay-add {
  color: #eff1f9;
  background-color: #4a1f18;
  padding: 8px 14px;
  border-radius: 4px;
}
.cart-pay-next {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 12px 20px;
  background-color: #4a1f18;
  color: #eff1f9;
  margin-top: 30px;
  border-radius: 8px;
}
@media screen and (max-width: 1200px) {
  .cart-table {
    width: 100%;
  }
  .cart-pay {
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .cart-heading {
    font-size: 24px;
    padding-top: 40px;
  }
  .cart-comback {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .cart-table thead tr td {
    padding: 10px 20px;
    font-size: 12px;
  }
  .cart-table tbody tr td {
    padding: 8px;
  }
  .cart-table tbody tr td:nth-child(3) {
    font-size: 16px;
  }
  .cart-table tbody tr td img {
    max-width: 60px;
  }
  .cart-table tbody tr td .icon {
    font-size: 20px;
  }
  .cart-table tbody tr td:nth-child(4),
  .cart-table tbody tr td:nth-child(6) {
    font-size: 16px;
  }
  .cart-table tbody tr td input {
    max-width: 40px;
    min-height: 20px;
  }
  .cart-info {
    flex-direction: column;
    gap: 20px;
    padding: 10px 0 30px;
  }
  .cart-table {
    width: 100%;
    overflow-x: auto;
  }
  .cart-pay {
    width: 100%;
  }
  .cart-pay-item {
    padding: 10px;
  }
  .cart-pay-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .cart-pay-total,
  .cart-pay-discout,
  .cart-pay-ship,
  .cart-pay-sub {
    margin-bottom: 8px;
  }
  .cart-discout {
    margin: 20px 0 10px;
  }
  .pay-code {
    padding: 6px 12px 6px 4px;
    width: 70%;
    margin-right: 8px;
  }
  .pay-add {
    padding: 6px 10px;
  }
  .cart-pay-next {
    padding: 10px 16px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .cart-heading {
    font-size: 20px;
    padding-top: 30px;
  }
  .cart-comback {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .cart-table thead tr td {
    padding: 10px 15px;
    font-size: 12px;
  }
  .cart-table tbody tr td {
    padding: 8px;
  }
  .cart-table tbody tr td:nth-child(3) {
    font-size: 14px;
  }
  .cart-table tbody tr td img {
    max-width: 50px;
  }
  .cart-table tbody tr td .icon {
    font-size: 18px;
  }
  .cart-table tbody tr td:nth-child(4),
  .cart-table tbody tr td:nth-child(6) {
    font-size: 14px;
  }
  .cart-table tbody tr td input {
    max-width: 30px;
    min-height: 20px;
  }
  .cart-info {
    margin-bottom: 10px;
    padding: 10px 0 20px;
    display: block;
    overflow-x: auto;
  }
  .cart-table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 10px;
  }
  .cart-pay {
    width: 100%;
  }
  .cart-pay-item {
    padding: 10px;
  }
  .cart-pay-title {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .cart-pay-total,
  .cart-pay-discout,
  .cart-pay-ship,
  .cart-pay-sub {
    margin-bottom: 6px;
  }
  .cart-discout {
    margin: 10px 0 10px;
  }
  .pay-code {
    padding: 6px 10px 6px 4px;
    width: 70%;
    margin-right: 6px;
  }
  .pay-add {
    padding: 6px 8px;
  }
  .cart-pay-next {
    padding: 8px 14px;
    margin-top: 10px;
  }
}
