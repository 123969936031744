.home-product {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  margin-top: 20px;
  padding: 20px 0;
}
.product-heading {
  font-size: 32px;
  text-align: center;
  font-weight: bold;
  line-height: 1.5em;
  padding-top: 50px;
  color: #4a1f18;
}
.products-image {
  border-radius: 10px;
  max-width: 370px;
}

.products-expired {
  position: absolute;
  top: 0;
  left: 0;
}

.products-info {
  margin-top: 20px;
  text-align: center;
}

.products-name {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
}

.products-price {
  font-size: 16px;
  font-weight: 400;
}

.products-price span {
  font-weight: 700;
  margin-right: 40px;
}

.custum-price span {
  font-weight: 700;
}

.products-social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.products-social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 50px;
  height: 50px;
  font-size: 20px;
  transition: all 0.25s ease;
  color: #ffffff;
}
.products-social-link.active {
  color: #dc143c;
}
.products-social-link i {
  pointer-events: none;
}

.products-social-item button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.products-social-link:hover {
  color: #ff9f1c;
}

.products-main {
  padding-bottom: 20px;
}
.products-main-content {
  position: relative;
  z-index: 1;
  transition: transform 0.25s linear;
  will-change: transform;
}

.products-main-content .products-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s linear;
  background: linear-gradient(
    to bottom,
    rgb(158, 85, 52, 0.749),
    rgba(158, 85, 52, 0.4)
  );
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 10px;
}

.products-main-content:hover .products-content {
  opacity: 1;
  visibility: visible;
}
.products-color-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}
.products-color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.products-color-item.active {
  padding: 15px;
  border: 3px solid #000000;
}

.phantrang1 {
  display: flex;
  justify-content: center;
  padding: 40px 0 60px 0;
}

.phantrang1 li {
  list-style: none;
}

.phantrang1 a {
  margin: 0 5px;
  padding: 8px 14px;
  border-radius: 10px;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
}

.phantrang1 a:hover,
.phantrang1 .active a {
  background-color: #4a1f18;
  color: #fff;
}
/* Định dạng div .filter */
.filter {
  display: flex;
  justify-content: space-between; /* Căn chỉnh các mục trong div với khoảng cách giữa chúng */
  align-items: center; /* Căn chỉnh nội dung theo chiều dọc */
  margin: 20px 0; /* Khoảng cách giữa div .filter và các phần tử bên dưới */
  padding: 10px; /* Khoảng cách giữa nội dung và biên của div */
  background-color: #f1f1f1; /* Màu nền nhạt */
  border-radius: 5px; /* Đường cong nhẹ ở các góc */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Hiệu ứng đổ bóng nhẹ */
}

/* Định dạng thẻ select trong div .filter-size */
.filter-size select {
  padding: 8px; /* Khoảng cách giữa nội dung và biên của thẻ select */
  border: 1px solid #ccc; /* Đường viền nhạt */
  border-radius: 4px; /* Đường cong nhẹ ở các góc */
  font-size: 14px; /* Kích thước phông chữ trung bình */
  background-color: #fff; /* Màu nền trắng */
  color: #333; /* Màu chữ */
  cursor: pointer; /* Con trỏ hình tay khi di chuột qua */
  transition: border-color 0.3s ease; /* Hiệu ứng chuyển đổi màu đường viền */
}

/* Hiệu ứng khi thẻ select được chọn */
.filter-size select:focus {
  border-color: #007bff; /* Màu đường viền khi được chọn */
  outline: none; /* Loại bỏ đường viền mặc định của trình duyệt */
}
@media screen and (max-width: 1023px) {
  .home-product {
    grid-template-columns: repeat(2, 2fr);
  }
  .products-image {
    max-width: 100%;
  }
  .products-info {
    margin-top: 10px;
  }
  .products-name {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .products-price {
    font-size: 14px;
  }
  .products-price span {
    margin-right: 20px;
  }
  .products-social-link {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .products-social-link i {
    font-size: 16px;
  }
  .products-main-content .products-content {
    border-radius: 5px;
  }
  .products-color-item.active {
    padding: 10px;
    border: 2px solid #000000;
  }
  .filter {
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-size select {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .home-product {
    grid-template-columns: repeat(1, 2fr);
    padding: 0 10px;
  }
  .products-image {
    max-width: 100%;
  }
  .products-info {
    margin-top: 10px;
  }
  .products-name {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .products-price {
    font-size: 14px;
  }
  .products-price span {
    margin-right: 20px;
  }
  .products-social-link {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .products-social-link i {
    font-size: 16px;
  }
  .products-main-content .products-content {
    border-radius: 5px;
  }
  .products-color-item.active {
    padding: 10px;
    border: 2px solid #000000;
  }
  .filter {
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-size select {
    width: 100%;
  }
}
